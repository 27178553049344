import Link from 'next/link';

import { Button, ButtonLink, Buttons, Dropdown, DropdownDivider, DropdownItem, DropdownLink } from '@/common/components/controls';
import { createComponent, If, Slot } from '@/common/util/templateHelpers';
import { Block, Container, Flex, IconFA, RouterLink } from '@/common/components';

import style from './index.module.scss';
import AgSearch from '@/components/AgSearch';
import { faChevronDown, faGamepad, faShoppingBag, faSignInAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useModal } from '@/common/hooks/modal';
import { useAuthStore } from '@/stores/auth';
import UserAvatar from '../UserAvatar';
import { useThemeStore } from '@/stores/theme';
import { useLogout } from '@/hooks/useLogout';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import BlockPod from '@/components/BlockPod';
import { faFacebook, faInstagram, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';

export default createComponent('DesktopHeader', { style }, function DesktopHeader ({ className }) {
  const { openModal } = useModal();
  const [ authState ] = useAuthStore();
  const [ themeState ] = useThemeStore();
  const [ menuVisible, setMenuVisible ] = useState(false);
  const dropdownOverlayElRef = useRef<HTMLDivElement>(null);

  const logout = useLogout(); 
  const router = useRouter();

  const [ isCurrentRoute, setIsCurrentRoute ] = useState(router.asPath === '/');
  useEffect(() => {
    setIsCurrentRoute(router.asPath === '/');
  }, [ router.asPath ]);

  const toggleMenu = (isVisible?: boolean) => {
    const visible = isVisible === undefined ? !menuVisible : isVisible;
    setMenuVisible(visible);
    if (visible) {
      dropdownOverlayElRef.current?.classList.add('DesktopHeader__DropdownOverlay--visible');
    } else {
      dropdownOverlayElRef.current?.classList.remove('DesktopHeader__DropdownOverlay--visible');
    }
  };

  const logoProps = themeState.theme === 'default' 
    ? {
      src: '/images/logo.svg',
      width: '274',
      height: '24',
    }
    : {
      src: '/images/logoClassic.svg',
      width: '174',
      height: '32',
    };
  
  return (
    <>
      <div 
        ref={dropdownOverlayElRef} 
        className='DesktopHeader__DropdownOverlay' 
        onClick={() => toggleMenu()}
      />
      <Container className={className}>
        <Flex wide justifySpaceBetween alignCenter>
          <Flex justifySpaceBetween>
            <Flex alignCenter className={isCurrentRoute ? '--disableLink' : ''}>
              <Link href='/'>
                {
                  If(themeState.theme === 'default', () => (
                    <a
                      className='DesktopHeader__Logo' 
                      style={{ fontSize: 0 }}><img
                        alt='Addicting Games'
                        { ...logoProps }
                      /></a>
                  ))
                    .Else(() => (
                      <a
                        className='DesktopHeader__Logo' 
                        style={{ fontSize: 0 }}><img
                          alt='Addicting Games'
                          { ...logoProps }
                        /></a>
                    ))
                    .EndIf()
                }
              </Link>
            </Flex>
            <Flex gap0 container justifyEnd className='--padMd2__l'>
              <AgSearch placeholder='Search' />
              <Button tertiary onClick={() => toggleMenu()}>
                <IconFA icon={faGamepad} />
                <span className='--padSm1__l --padSm1__r'>Find a Game</span>
                <IconFA icon={faChevronDown} small />
              </Button>
            </Flex>
          </Flex>
          <Flex justifyEnd>
            <Buttons>
              {/*<ButtonLink target='_blank' href='https://developers.addictinggames.com/'>*/}
              {/*  Developers*/}
              {/*</ButtonLink>*/}
              {/*<ButtonLink target='_blank' href={process.env.APP_CONFIG.MERCH_URL}>*/}
              {/*  Merch*/}
              {/*</ButtonLink>*/}
              {/*<ButtonLink href='/game-pass' className='DesktopHeader__GamepassUpgradeButton'>*/}
              {/*  {*/}
              {/*    If(themeState.theme === 'default', () => (*/}
              {/*      <strong><img src='/images/gamePass.svg' width='104' height='16' alt='GamePass' /></strong>*/}
              {/*    ))*/}
              {/*      .Else(() => (*/}
              {/*        <strong><img src='/images/gamePassClassic.svg' width='104' height='16' alt='GamePass' /></strong>*/}
              {/*      ))*/}
              {/*      .EndIf()*/}
              {/*  }*/}
              {/*  {*/}
              {/*    If(!authState.user?.hasGamePass, () => (*/}
              {/*      <small>Upgrade</small>*/}
              {/*    )).EndIf()*/}
              {/*  }*/}
              {/*</ButtonLink>*/}
              {
                If(authState.ready && !authState.auth, () => (
                  <Button 
                    tertiary 
                    onClick={() => openModal('login')}
                    ariaLabel='Open Login Modal'
                  >
                    <IconFA icon={faSignInAlt} style={{ margin: '0' }} />
                    <span className='--hideDesktop --inlineFlexHighdef --marSm2__l'>Sign In</span>
                  </Button>
                )).EndIf()
              }
            </Buttons>
            {
              If(authState.ready && authState.auth, () => (
                <Dropdown right>
                  <Slot name='trigger'>
                    <UserAvatar 
                      avatarUrl={authState.user.userPicture} 
                      displayName={authState.user.displayName}
                    />
                  </Slot>
                  <Slot name='content'>
                    <DropdownItem tag='span'><strong>{authState.user.displayName}</strong></DropdownItem>
                    <DropdownDivider />
                    <DropdownLink href='/account/edit'>My Account</DropdownLink>
                    <DropdownItem onClick={logout}>Logout</DropdownItem>
                  </Slot>
                </Dropdown>
              )).EndIf()
            }
          </Flex>
        </Flex>
        {
          If(menuVisible, () => (
            <div className='DesktopHeader__Dropdown' onClick={() => toggleMenu(false)}>
              <div className='DesktopHeader__DropdownInner'>
                <div>
                  <BlockPod>
                    <Slot name='title'>Find a Game</Slot>
                    <Flex directionColumn>
                      <Flex container gap0 wrap>
                        <ButtonLink tertiary large  href='/new-games'>
                          <Flex>
                            <img src='/images/header/new.png' alt='new games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>New Games</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/most-addicting-games'>
                          <Flex>
                            <img src='/images/header/mostAddicting.png' alt='most addicting icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Most Addicting</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/trending-games'>
                          <Flex>
                            <img src='/images/header/trending.png' alt='trending icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Trending</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/top-games'>
                          <Flex>
                            <img src='/images/header/top.png' alt='top games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Top 100</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/account/edit#favoriteGames'>
                          <Flex>
                            <img src='/images/header/liked.png' alt='liked games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Liked Games</strong>
                              {/* <small>All Your Favorites</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/account/edit#recentlyPlayed'>
                          <Flex>
                            <img src='/images/header/recent.png' alt='recently played icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Played Games</strong>
                              {/* <small>Your Recently Played</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                      </Flex>
                    </Flex>
                  </BlockPod>
                  <hr />
                  <BlockPod>
                    <Slot name='title'>Popular Categories</Slot>
                    <Slot name='aside'><RouterLink href={'/all-categories'}>View More</RouterLink></Slot>
                    <Flex directionColumn className='--secondRow'>
                      <Flex container gap0>
                        <ButtonLink tertiary large  href='/io-games'>
                          <Flex>
                            <img src='/images/header/io.png' alt='io games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>IO</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/tag/arcade-games'>
                          <Flex>
                            <img src='/images/header/arcade.png' alt='arcade games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Arcade</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/tag/adventure-games'>
                          <Flex>
                            <img src='/images/header/adventure.png' alt='adventure games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Adventure</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/sports-games'>
                          <Flex>
                            <img src='/images/header/sports.png' alt='sports games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Sports</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/mmo-games'>
                          <Flex>
                            <img src='/images/header/mmo.png' alt='mmo games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>MMO</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/puzzle-games'>
                          <Flex>
                            <img src='/images/header/puzzle.png' alt='puzzle games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Puzzle</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                      </Flex>
                      <Flex container gap0>
                        <ButtonLink tertiary large  href='/shooting-games'>
                          <Flex>
                            <img src='/images/header/shooter.png' alt='shooting games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Shooting</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/strategy-games'>
                          <Flex>
                            <img src='/images/header/strategy.png' alt='strategy games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Strategy</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/clicker-games'>
                          <Flex>
                            <img src='/images/header/clicker.png' alt='clicker games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Clicker</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/tag/racing-games'>
                          <Flex>
                            <img src='/images/header/racing.png' alt='racing games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Racing</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/funny-games'>
                          <Flex>
                            <img src='/images/header/funny.png' alt='funny games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Funny</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                        <ButtonLink tertiary large  href='/action-games'>
                          <Flex>
                            <img src='/images/header/action.png' alt='action games icon' />
                            <Flex directionColumn justifyCenter>
                              <strong>Action</strong>
                              {/* <small>549 games</small> */}
                            </Flex>
                          </Flex>
                        </ButtonLink>
                      </Flex>
                    </Flex>
                  </BlockPod>
                  <Block>
                    <p className='--padSm2__b'><strong>Follow Us</strong></p>
                    <Buttons>
                      <ButtonLink tertiary href='https://www.facebook.com/AddictingGames' target='_blank'>
                        <IconFA icon={faFacebook} />
                        <span>Facebook</span>
                      </ButtonLink>
                      <ButtonLink tertiary href='https://www.instagram.com/theofficialaddictinggames' target='_blank'>
                        <IconFA icon={faInstagram} />
                        <span>Instagram</span>
                      </ButtonLink>
                      <ButtonLink tertiary href='https://www.tiktok.com/@addictinggames.com' target='_blank'>
                        <IconFA icon={faTiktok} />
                        <span>Tiktok</span>
                      </ButtonLink>
                      <ButtonLink tertiary href='https://twitter.com/AddictingGames' target='_blank'>
                        <IconFA icon={faTwitter} />
                        <span>Twitter</span>
                      </ButtonLink>
                    </Buttons>
                  </Block>
                </div>
              </div>
              {/*{*/}
              {/*  If(authState.ready && !authState.user?.hasGamePass, () => (*/}
              {/*    <Link href='/game-pass'>*/}
              {/*      <Flex alignCenter wide className='GamePass__Banner'>*/}
              {/*        <Flex>*/}
              {/*          <p>*/}
              {/*            <img src='/images/gamePass.svg' alt='Get GamePass' /><br />*/}
              {/*            <span>Remove ads and get to games faster with GamePass!</span>*/}
              {/*          </p>*/}
              {/*        </Flex>*/}
              {/*        <Flex pullRight>*/}
              {/*          <ButtonLink primary href='/game-pass'>*/}
              {/*            GET IT NOW*/}
              {/*          </ButtonLink>*/}
              {/*        </Flex>*/}
              {/*      </Flex>*/}
              {/*    </Link>*/}
              {/*  )).EndIf()*/}
              {/*}*/}
              <div className='DesktopHeader__DropdownInner'>
                <div>
                  <ul>
                    <li>
                      <a href='https://addictinggameshelp.zendesk.com/hc/en-us/requests/new?tf_1500003702161=addictinggames.com&tf_25644328=game_submissions'>Upload Your Game</a>
                    </li>
                    <li>
                      <Link href='/about/upload'>Developer FAQ</Link>
                    </li>
                    <li>
                      <a href='https://shop.addictinggames.com/'>Merch</a>
                    </li>
                    <li>
                      <Link href='/blog'>Blog</Link>
                    </li>
                    <li>
                      <a href='https://addictinggameshelp.zendesk.com/hc/en-us/requests/new'>Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )).EndIf()
        }
      </Container>
    </>
  );
});
