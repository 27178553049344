import { FocusEventHandler, useState } from 'react';
import { useRouter } from 'next/router';
import { createComponent, For, If, IntrinsicProps } from '@/common/util/templateHelpers';
import { Autocomplete } from '@/common/components/controls';
import style from './index.module.scss';
import { useAutocomplete } from '@/hooks/useAutoComplete';
import AgAutocompleteGameItem from '../AgAutocompleteGameItem';
import AgAutocompleteTagItem from '../AgAutocompleteTagItem';
import { Flex } from '@/common/components';

interface AgSearchProps extends IntrinsicProps {
  className?: string,
  placeholder?: string,
  findItemPath?: string,
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  noIcon?: boolean,
  up?: boolean,
  right?: boolean,
}

export default createComponent<AgSearchProps>('AgSearch', 
  { style }, 
  function AgSearch ({ className }, props) {
    const [ query, setQuery ] = useState('');
    const autocomplete = useAutocomplete({ query });
    const router = useRouter();

    const onSearchUpdate = (query) => {
      setQuery(() => query);
    };

    const onSearchSubmit = (query) => {
      router.push(`/search/${query}`);
    };

    const onSelect = (item) => {
      router.push(item.path);
    };

    const findItemPath = (item) => {
      return item.path;
    };

    return (
      <div ref={autocomplete.ref}>
        <Autocomplete
          className={className}
          placeholder={props.placeholder || ''}
          findItemPath={findItemPath}
          onSearchUpdate={onSearchUpdate}
          onSearch={onSearchSubmit}
          onSelect={onSelect}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          noIcon={props.noIcon}
          up={props.up}
          right={props.right}
          showMenu={autocomplete.showMenu}
          loading={autocomplete.loading}
        >
          {
            If(autocomplete.searchResults.results.length === 0 && autocomplete.searchResults.tags.length === 0, () => (
              <Flex wide className='--padMd0 --padMd2__b'>
                <Flex center>No results found.</Flex>
              </Flex>
            )).EndIf()
          }
          {
            For(autocomplete.searchResults.results, (item, index) => (
              <AgAutocompleteGameItem
                title={item.title}
                thumbnail={item.thumbnailUrl}
                rating={item.rating}
                genre={item.genre.name}
                featured={false}
                trending={false}
                key={'search_game_' + index}
                path={item.path}
              />
            ))
          }
          {
            For(autocomplete.searchResults.tags, (item, index) => (
              <AgAutocompleteTagItem
                name={item.name}
                games={item.games}
                path={item.path}
                key={'search_tag_' + index}
              />
            ))
          }
        </Autocomplete>
      </div>
    );
  });
