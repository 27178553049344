import axios from '@/apis/drupal/instance';
import { toQuery } from '@/common/util/networkRequest';
import gameApi from '@/apis/drupal/game';
import { Game } from '@/util/formatters';

export interface SearchResult {
  tags: SearchTagItem[]
  results: Game[]
}

export interface SearchError {
  error: string
}

export interface SearchTagItem {
  name: string
  description: string
  path: string
  hexColor: string
  longDescriptionTags: string
  imageClass: string
  games?: number
}

export default class Search {
  static formatTag (tag): SearchTagItem {
    return {
      name: tag.attributes.name,
      description: tag.attributes.description.value,
      path: tag.attributes.path.alias,
      hexColor: tag.attributes.field_border_hex_color,
      longDescriptionTags: tag.attributes.long_description_tags?.value,
      imageClass: tag.attributes.field_tag_image_class,
      games: tag.games,
    };
  }

  static async getSearchResults (searchQuery: string): Promise<SearchResult> {
    const tagQuery = toQuery('/jsonapi/taxonomy_term/tags', {
      filter: {
        name: {
          operator: 'STARTS_WITH',
          value: searchQuery,
        }
      },
    });

    let tags: SearchTagItem[] = [];
    try {
      const result = await axios.request<any[]>({
        method: 'get',
        url: tagQuery,
      });
      tags = result.data.map(Search.formatTag);
    } catch (err) {
      throw err;
    }

    let searchResults: Game[] = [];
    try {
      searchResults = await gameApi.listByTitle(searchQuery,'-field_ratingb', null, null, 10, 0);
    } catch (err) {
      throw err;
    }

    for (let i = 0; i < tags.length; i++) {
      const tag = tags[ i ];

      const gamesQuery = toQuery('/jsonapi/node/game', {
        fields: {
          'node--game': [
            'title',
            'field_ratingb',
            'field_video_thumbnail',
            'field_thumbnail',
            'field_genre',
          ]
        },
        filter: {
          status: 1,
          field_hide_from_listings: 0,
          field_game_pass_only_: {
            value: 1,
            operator: '<>',
          },
          'field_tags.name': {
            operator: '=',
            value: tag.name,
          },
        },
        sort: '-created',
        include: 'field_thumbnail',
        page: {
          limit: 1,
        },
      });

      const gameResponse = await axios.request({
        method: 'get',
        url: gamesQuery,
      });

      tags[ i ].games = (gameResponse as any).meta.count;
    }

    return {
      tags,
      results: searchResults,
    };
  }
}
