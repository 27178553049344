import Link from 'next/link';
import { Container, Content, Flex, IconFA, RouterLink } from '@/common/components';
import { createComponent, If } from '@/common/util/templateHelpers';

import style from './index.module.scss';
import { setTheme, useThemeStore } from '@/stores/theme';
import GameTileContainer from '@/components/GameTileContainer';
import { faFacebook, faInstagram, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';

export default createComponent('AppFooter', 
  { style }, 
  function AppFooter ({ className }, props) {
    const [ themeState, dispatchTheme ] = useThemeStore();
  
    return (
      <footer className={className} >
        <Container>
          <Flex wide>
            <Flex center directionColumn>
              <Flex center>
                <Link href='/'>
                  {
                    If(themeState.theme === 'default', () => (
                      <a className='AppFooter__Logo'><img
                        src='/images/logo.svg'
                        alt='Addicting Games'
                        key='/images/logo.svg'
                      /></a>
                    ))
                      .Else(() => (
                        <a className='AppFooter__Logo'><img
                          src='/images/logoClassic.svg'
                          alt='Addicting Games'
                          key='/images/logoClassic.svg'
                        /></a>
                      ))
                      .EndIf()
                  }
                </Link>
              </Flex>

              <Flex gap0 container alignCenter justifyCenter className='AppFooter__Socials --marSm2__y'>
                <Flex>
                  <a aria-label='Addicting Games on Facebook' href='https://www.facebook.com/AddictingGames/' target='_blank' rel='noopener noreferrer'>
                    <IconFA icon={faFacebook} />
                  </a>
                </Flex>
                <Flex>
                  <a aria-label='Addicting Games on X' href='https://twitter.com/AddictingGames' target='_blank' rel='noopener noreferrer'>
                    <IconFA icon={faTwitter} />
                  </a>
                </Flex>
                <Flex>
                  <a aria-label='Addicting Games on TikTok' href='https://tiktok.com/@addictinggames.com' target='_blank' rel='noopener noreferrer'>
                    <IconFA icon={faTiktok} />
                  </a>
                </Flex>
                <Flex>
                  <a aria-label='Addicting Games on Instagram' href='https://instagram.com/theofficialaddictinggames' target='_blank' rel='noopener noreferrer'>
                    <IconFA icon={faInstagram} />
                  </a>
                </Flex>
              </Flex>

              <Flex alignCenter justifyCenter collapseMobile className='AppFooter__Links --marSm1__y'>
                <Flex><RouterLink href='/all-games'>All Games</RouterLink></Flex>
                {/*<Flex><RouterLink href='https://developers.addictinggames.com' target='_blank'>Developers</RouterLink></Flex>*/}
                <Flex><RouterLink href='/about/about-us'>About Us</RouterLink></Flex>
                <Flex><RouterLink href='/about/contact-us'> Contact Us</RouterLink></Flex>
                <Flex><RouterLink href='/about/advertisers'>Advertisers</RouterLink></Flex>
                <Flex><RouterLink href='/about/terms-use'> Terms &amp; Conditions</RouterLink></Flex>
                <Flex><RouterLink href='/about/privacy-policy'>Privacy Policy</RouterLink></Flex>
              </Flex>

              <Flex className='--marSm3__y --textCenter'>
                <GameTileContainer>
                  <p>
                    Welcome to Addicting Games, home of thousands of free online video games that can be played on nearly every phone, tablet, and PC, with no software downloads necessary. 
                    Our constantly updating library of free games includes classic games like Bubble Spinner, Mahjongg, and Impossible Quiz, along with new and current favorites like Fruit Merge, Little Big Snake, and Apes.io. 
                    Whether you're looking for adventure games, shooting games, matching games, daily games, or 'endless' games, you're sure to find something in our expansive library of over 5000 free games that you can play right now!
                  </p>
                </GameTileContainer>
              </Flex>
              
              <Flex justifyCenter>
                <p>
                  <br />
                  Copyright © {(new Date()).getFullYear()} Addicting Games, Inc.
                </p>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </footer>
    );
  });
