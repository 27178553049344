import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import anime from 'animejs';

import { faBars, faCompress, faGamepad, faHeart, faHome, faPlus, faSearch, faShoppingBag, faTag, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartOutline } from '@fortawesome/free-regular-svg-icons';
import storage from '@/common/util/storage';
import { GameBasic } from '@/util/formatters';
import { createComponent, For, If, IntrinsicProps, Slot, toClassName } from '@/common/util/templateHelpers';
import { Container, Flex, IconFA, Loader, gamePlayerEventListener } from '@/common/components';
import { Button, ButtonLink, Dropdown, DropdownItem, DropdownLink } from '@/common/components/controls';
import { LoginForm } from '@/common/components/forms';
import AgSearch from '@/components/AgSearch';
import BlockHeader from '@/components/BlockHeader';
import AgGameTile from '@/components/AgGameTile';
import UserAvatar from '@/components/UserAvatar';
import GameTileFilmStrip from '@/components/GameTileFilmStrip';

import { useAuthStore } from '@/stores/auth';
import { useThemeStore } from '@/stores/theme';
import { useFavoriteGamesStore } from '@/stores/favoriteGames';

import { useModal } from '@/common/hooks/modal';

import style from './index.module.scss';
import { useLogout } from '@/hooks/useLogout';
import { EventListener, EventMap } from '@/util/eventListener';


enum ButtonPositions {
  topLeft = 'TopLeft',
  topRight = 'TopRight',
  bottomLeft = 'BottomLeft',
  bottomRight = 'BottomRight'
}

type PanelName = 'search' | 'user' | false

export type MobileEvents = EventMap & {
  'favorite_status_updated': (event: { isFavorite: boolean }) => void
  'toggle_favorite_requested': (event: { shouldFavorite: boolean }) => void
  'show_recommended_games': (event: { games: GameBasic[] }) => void
}

class MobileHeaderEventListener extends EventListener<MobileEvents> {}

export const eventListener = new MobileHeaderEventListener();

interface UserPanelProps extends IntrinsicProps {

}

const UserPanel = createComponent<UserPanelProps>('MobileHeader__UserPanel', {}, function MobileHeaderUserPanel ({ className }, props) {
  const [ state ] = useAuthStore();
  const [ favoriteGames, setFavoriteGames ] = useState<GameBasic[]>([]);
  const [ isLoggedIn, setIsLoggedIn ] = useState<boolean>(false);
  const [ favoriteGamesState ] = useFavoriteGamesStore();
  const [ offset, setOffset ] = useState(0);

  useEffect(() => {
    setIsLoggedIn(state.ready && !!state.auth && !!state.user);
  }, [ state.ready, state.auth, state.user ]);
  
  const logout = useLogout();

  const pageSize = 5;

  useEffect(() => {
    setOffset(0);

    if(!favoriteGamesState?.fetched) {
      setFavoriteGames([]);
      return;
    }

    setFavoriteGames([ ...favoriteGamesState.games.slice(0, pageSize) ]);
  }, [ favoriteGamesState?.fetched, favoriteGamesState.gameIds, favoriteGamesState.games ]);

  useEffect(() => {
    if (offset === 0) return;

    setFavoriteGames((curGames) => [ ...curGames, ...favoriteGamesState.games.slice(offset * pageSize, (offset + 1) * pageSize) ]);
  }, [ offset, favoriteGamesState.games ]);

  return (
    <Flex directionColumn gap0 container className={className}>
      {
        If(isLoggedIn, () => (
          <>
            <Flex>
              <Flex alignCenter gap0 container className='MobileHeader__UserPanel__User'>
                <Flex>
                  <UserAvatar 
                    small
                    avatarUrl={state?.user?.userPicture} 
                    displayName={state?.user?.displayName} 
                  />
                </Flex>
                <Flex>
                  <strong>{state?.user?.displayName}</strong>
                </Flex>
              </Flex>
            </Flex>
            {
              If(favoriteGames.length > 0, () => (
                <GameTileFilmStrip>
                  {
                    For(favoriteGames, (game: GameBasic) => (
                      <AgGameTile game={game} key={game.nid} />
                    ))
                  }
                  {
                    If(favoriteGamesState.fetched && favoriteGames.length < favoriteGamesState.games.length, () => (
                      <Button onClick={() => setOffset(offset + 1)} style={{ flexShrink: 0 }}>More Games</Button>
                    )).EndIf()
                  }
                </GameTileFilmStrip>
              ))
                .Else(() => (
                  <Flex>
                    <Flex fit justifyCenter alignCenter className={'--padMd0 --textCenter'}>
                    You haven't favorited any games yet.
                    </Flex>
                  </Flex>
                ))
                .EndIf()
            }
            <Flex>
              <Flex container gap0 fit>
                <Flex fit>
                  <ButtonLink fullWidth primary href='/account/edit'>
                    <span>My Account</span>
                  </ButtonLink>
                </Flex>
                <Flex fit>
                  <Button fullWidth onClick={logout}>Logout</Button>
                </Flex>
              </Flex>
            </Flex>
          </>
        ))
          .Else(() => (
            <Flex className='--padMd2'><Loader /></Flex>
          ))
          .EndIf()
      }
    </Flex>
  );
});

interface MoreGamesPanelProps extends IntrinsicProps {
  games: GameBasic[]
}

const MoreGamesPanel = createComponent<MoreGamesPanelProps>('MobileHeader__MoreGamesPanel', {}, function MobileHeaderMoreGamesPanel ({ className }, props) {
  return (
    <Flex directionColumn gap0 container className={className}>
      <Flex>
        <BlockHeader>
          <span>Try out these games next!</span>
        </BlockHeader>
      </Flex>
      <Flex wrap>
        {
          For(props.games.filter((game) => game.supportsMobile).slice(0, 6), (game: GameBasic) => (
            <React.Fragment key={game.nid}>
              <AgGameTile game={game} instantPlay={true} />
              <div className='MobileHeader__MoreGamesPanel__Break' />
            </React.Fragment>
          ))
        }
      </Flex>
    </Flex>
  );
});

export default createComponent('MobileHeader', { style }, function MobileHeader ({ mergeClassNames }, props) {
  const ref = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const dropTargetsRef = useRef<HTMLDivElement>(null);
  const [ state ] = useAuthStore();

  const [ isLoggedIn, setIsLoggedIn ] = useState<boolean>(false);
  const [ mobileMenuOpen, setMobileMenuOpen ] = useState<boolean>(false);
  const [ openPanel, setOpenPanel ] = useState<PanelName>(false);
  const [ className, setClassName ] = useState<string>('MobileHeader');
  const [ buttonPosition, setButtonPosition ] = useState<ButtonPositions>(ButtonPositions.bottomRight);

  // If page navigation occurs, close the menu
  useEffect(() => {
    const closeMenu = () => setMobileMenuOpen(false);
    router.events.on('routeChangeStart', closeMenu);

    return () => router.events.off('routeChangeStart', closeMenu);
  }, [ router.events ]);

  // If the auth state changes, close the menu
  useEffect(() => setMobileMenuOpen(false), [ isLoggedIn ]);

  const { openModal } = useModal();

  const closeMenuAndOpenModal = modalName => {
    setMobileMenuOpen(false); 
    openModal(modalName); 
    return true;
  };

  useEffect(() => {
    setIsLoggedIn(state.ready && !!state.auth && !!state.user);
  }, [ state.ready, state.auth, state.user ]);

  useEffect(() => {
    setClassName(mergeClassNames(toClassName('MobileHeader', { 
      menuOpen: mobileMenuOpen,
      [ `position${buttonPosition}` ]: true,
      [ `${openPanel}PanelOpen` ]: openPanel
    })));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ mobileMenuOpen, openPanel, buttonPosition ]);

  const togglePanel = (panelName: PanelName) => {
    if (panelName === openPanel) {
      setOpenPanel(false);
      return;
    }

    setOpenPanel(panelName);
  };

  useEffect(() => {
    if (openPanel === 'search') {
      ref.current.querySelector<HTMLInputElement>('.AgSearch input').focus();
    }
  }, [ openPanel ]);

  const animateExtraItemsIn = () => {
    const targets = Array.from(ref.current.querySelectorAll('.MobileHeader__Menu__ExtraButtonItem'));
    targets.reverse(); // because the order in the DOM is backwards from what you'd intuitively expect
    if (!targets.length) return;

    const rect = targets[ 0 ].getBoundingClientRect();
    const margin = 10;
    const offset = 70;

    targets.forEach((target, i) => {
      anime.remove(target);

      const y = offset + (rect.height + margin) * i;

      anime({
        targets: target,
        delay: i * 20,
        opacity: {
          value: 1,
          easing: 'easeInOutSine',
          duration: 200
        },
        translateX: {
          duration: 600,
          easing: 'easeOutElastic(0.05, 0.4)',
          value: `-${y}px`
        }
      });
    });
  };

  const animateExtraItemsOut = () => {
    const targets = ref.current.querySelectorAll('.MobileHeader__Menu__ExtraButtonItem');
    if (!targets.length) return;

    targets.forEach((target, i) => {
      anime.remove(target);

      anime({
        targets: target,
        easing: 'easeInOutSine',
        duration: 200,
        opacity: 0,
        translateX: '0px'
      });
    });
  };

  useEffect(() => {
    if (mobileMenuOpen) {
      animateExtraItemsIn();

      document.body.style.overflowX = 'hidden';
      document.body.style.overflowY = 'hidden';
    } else {
      animateExtraItemsOut();
      togglePanel(false);

      document.body.style.overflowX = 'hidden';
      document.body.style.overflowY = 'scroll';
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ mobileMenuOpen ]);

  const [ gameIsFavorite, setFavorite ] = useState(false);
  const [ recommendedGames, setRecommendedGames ] = useState([]);
  const [ currentRecommendedGames, setCurrentRecommendedGames ] = useState([]);

  // Handle user toggling favourite game status
  useEffect(() => {
    // Listen to see if game page changed favorite status
    const favStatusListener = ({ isFavorite }) => {
      setFavorite(isFavorite);
    };
    eventListener.on('favorite_status_updated', favStatusListener);

    // Listen to see if game page recommended some games for us to show
    const recommendGamesListener = ({ games }) => {
      setRecommendedGames(games);
      setCurrentRecommendedGames(games);
    };
    eventListener.on('show_recommended_games', recommendGamesListener);

    return () => {
      eventListener.off('favorite_status_updated', favStatusListener);
      eventListener.off('show_recommended_games', recommendGamesListener);
    };
  }, []);

  const favoriteGame = () => {
    eventListener.trigger('toggle_favorite_requested', { shouldFavorite: !gameIsFavorite });
    setMobileMenuOpen(false);
  };

  const lastPathRef = useRef('');
  const [ isGamePage, setIsGamePage ] = useState(false);

  // Show/hide favourite controls based on page context
  useEffect(() => {
    if (router.pathname !== lastPathRef.current) {
      lastPathRef.current = router.pathname;
      setIsGamePage(router.pathname === '/[genre]/[slug]');
    }
  }, [ router.pathname ]);

  // Button position

  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const dragStartRef = useRef({ x: 0, y: 0 });
  const isDraggingRef = useRef(false);

  useEffect(() => {
    const storedPosition = storage.getLocal('pea-position');
    const initialPosition = typeof storedPosition === 'string' ? storedPosition as ButtonPositions : ButtonPositions.bottomRight;
    
    menuButtonRef.current.classList.add(`MobileHeader__MenuButton--${initialPosition}`);
    menuButtonRef.current.classList.remove(`MobileHeader__MenuButton--${initialPosition}`);

    setButtonPosition(initialPosition);
  }, []);

  const getClientPos = (evt: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent) => {
    const touchEvent = evt as React.TouchEvent;
    if (touchEvent.touches && touchEvent.touches.length) {
      return {
        x: touchEvent.touches[ 0 ].clientX,
        y: touchEvent.touches[ 0 ].clientY,
      };
    }

    const mouseEvent = evt as React.MouseEvent;
    return {
      x: mouseEvent.clientX,
      y: mouseEvent.clientY,
    };
  };

  const startDrag = (evt: React.MouseEvent | React.TouchEvent, events: 'mouse' | 'touch') => {
    if (mobileMenuOpen) return;

    dropTargetsRef.current.classList.add('MobileHeader__DropTargets--open');

    const clientPos = getClientPos(evt);
    dragStartRef.current.x = clientPos.x;
    dragStartRef.current.y = clientPos.y;

    if (events === 'touch') {
      document.body.style.overflowX = 'hidden';
      document.body.style.overflowY = 'hidden';
    }

    const next = { x: 0, y: 0 };

    const xy = (evt: MouseEvent | TouchEvent) => {
      const clientPos = getClientPos(evt);

      const x = dragStartRef.current.x - clientPos.x;
      const y = dragStartRef.current.y - clientPos.y;

      return [ x, y ];
    };

    const dragMoveHandler = (evt: MouseEvent | TouchEvent) => {
      if (evt.cancelable) evt.preventDefault();
      evt.stopImmediatePropagation();

      const clientPos = getClientPos(evt);

      const [ x, y ] = xy(evt);

      const dragThreshold = 10;

      if (
        isDraggingRef.current || 
        Math.abs(dragStartRef.current.x - clientPos.x) > dragThreshold || 
        Math.abs(dragStartRef.current.y - clientPos.y) > dragThreshold
      ) {
        if (!isDraggingRef.current) {
          isDraggingRef.current = true;
          menuButtonRef.current.classList.add('MobileHeader__MenuButton--dragging');
        }
        next.x = x;
        next.y = y;
        menuButtonRef.current.style.transform = `translateX(${-x}px) translateY(${-y}px)`;

        const buttonElBrect = menuButtonRef.current.getBoundingClientRect();
        const buttonX = buttonElBrect.x;
        const buttonY = buttonElBrect.y;
        const isTop = buttonY <= window.innerHeight / 2;
        const isLeft = buttonX <= window.innerWidth / 2;
        let currentPosition;
        if (isTop) {
          if (isLeft) {
            currentPosition = ButtonPositions.topLeft;
          } else {
            currentPosition = ButtonPositions.topRight;
          }
        } else {
          if (isLeft) {
            currentPosition = ButtonPositions.bottomLeft;
          } else {
            currentPosition = ButtonPositions.bottomRight;
          }
        }
        dropTargetsRef.current.querySelectorAll('div').forEach((el) => el.classList.remove('--active'));
        if (currentPosition) dropTargetsRef.current.querySelector(`[data-position=${currentPosition}]`).classList.add('--active');
      }
    };

    const dragStopHandler = (evt: MouseEvent | TouchEvent) => {
      if (isDraggingRef.current) {
        const buttonElBrect = menuButtonRef.current.getBoundingClientRect();
        let buttonX = buttonElBrect.x;
        let buttonY = buttonElBrect.y;

        const isTop = buttonY <= window.innerHeight / 2;
        const isLeft = buttonX <= window.innerWidth / 2;

        let topPos = 0;
        let leftPos = 0;
        const buttonWidth = 60;

        let currentPosition: ButtonPositions = null;

        if (isTop) {
          if (isLeft) {
            currentPosition = ButtonPositions.topLeft;
          } else {
            currentPosition = ButtonPositions.topRight;
            leftPos = window.innerWidth;
            buttonX += buttonWidth;
          }
        } else {
          topPos = window.innerHeight;
          buttonY += buttonWidth;

          if (isLeft) {
            currentPosition = ButtonPositions.bottomLeft;
          } else {
            currentPosition = ButtonPositions.bottomRight;
            leftPos = window.innerWidth;
            buttonX += buttonWidth;
          }
        }

        menuButtonRef.current.style.transform = 'translateX(0%) translateY(0%)';

        const curLeft = ((buttonX / window.innerWidth) * 100) + '%';
        const curTop = ((buttonY / window.innerHeight) * 100) + '%';
        const nextLeft = ((leftPos / window.innerWidth) * 100) + '%';
        const nextTop = ((topPos / window.innerHeight) * 100) + '%';

        anime({
          targets: menuButtonRef.current,
          left: [ curLeft, nextLeft ],
          top: [ curTop, nextTop ],
        }).finished.then(() => {
          if (currentPosition !== buttonPosition) {
            menuButtonRef.current.classList.remove(`MobileHeader__MenuButton--${currentPosition}`);
            setButtonPosition(currentPosition);
            menuButtonRef.current.classList.add(`MobileHeader__MenuButton--${currentPosition}`);
            storage.setLocal('pea-position', currentPosition);
          }
        });
      }

      isDraggingRef.current = false;

      dropTargetsRef.current.classList.remove('MobileHeader__DropTargets--open');
      dropTargetsRef.current.querySelectorAll('div').forEach((el) => el.classList.remove('--active'));
      menuButtonRef.current.classList.remove('MobileHeader__MenuButton--dragging');

      document.removeEventListener(events === 'mouse' ? 'mouseup' : 'touchend', dragStopHandler);
      document.removeEventListener(events === 'mouse' ? 'mousemove' : 'touchmove', dragMoveHandler);

      document.body.style.overflowX = 'hidden';
      document.body.style.overflowY = 'scroll';
    };

    document.addEventListener(events === 'mouse' ? 'mouseup' : 'touchend', dragStopHandler);
    document.addEventListener(events === 'mouse' ? 'mousemove' : 'touchmove', dragMoveHandler, { passive: false });
  };

  const [ themeState, dispatchTheme ] = useThemeStore();

  const menuButtonClassNames = `${toClassName('Button', '&--large', '&--rounded', '&--primary')} ${toClassName('MobileHeader__MenuButton', `&--${buttonPosition}`, { dragging: isDraggingRef.current })}`;

  const menuItems = [
    { label: 'Games', icon: faGamepad, value: '/all-games' },
    { label: 'Multiplayer', icon: faUsers, value: '/multiplayer-games' },
    // { label: 'Merch', icon: faShoppingBag, value: process.env.APP_CONFIG.MERCH_URL },
    { label: 'Categories', icon: faTag, value: '/all-categories' },
    // { label: 'Game Pass', icon: null, value: '/game-pass' }
  ];

  const [ fullScreen, setFullScreen ] = useState(false);
  useEffect(() => {
    const handler = gamePlayerEventListener.on(
      'fullScreenChange', 
      (event) => setFullScreen(event.fullScreen)
    );

    return () => {
      gamePlayerEventListener.off('fullScreenChange', handler);
    };
  }, []);

  const exitFullScreen = () => {
    gamePlayerEventListener.trigger('exitFullScreen');
    setMobileMenuOpen(false);
  };

  return (
    <Container className={className} wide>
      <div className='MobileHeader__DropTargets' ref={dropTargetsRef}>
        <div data-position={ButtonPositions.topLeft} />
        <div data-position={ButtonPositions.bottomLeft} />
        <div data-position={ButtonPositions.topRight} />
        <div data-position={ButtonPositions.bottomRight} />
      </div>
      <div className='MobileHeader__ButtonContainer'>
        <button
          className={menuButtonClassNames}
          onTouchStart={(evt) => {
            startDrag(evt, 'touch');
          }}
          onClick={(evt) => {
            // Need to use click event to register 'tap' event for toggling menu
            // touchend won't work because it fires twice in this situation
            if (!(evt as unknown as TouchEvent).touches) return;
            if (!isDraggingRef.current) {
              if (!mobileMenuOpen) setCurrentRecommendedGames(recommendedGames);
              setMobileMenuOpen(!mobileMenuOpen);
            }
          }}
          onMouseDown={(evt) => {
            if ((evt as unknown as TouchEvent).touches) return;
            startDrag(evt, 'mouse');
          }}
          onMouseUp={(evt) => {
            if ((evt as unknown as TouchEvent).touches) return;
            if (!isDraggingRef.current) {
              if (!mobileMenuOpen) setCurrentRecommendedGames(recommendedGames);
              setMobileMenuOpen(!mobileMenuOpen);
            }
          }}
          ref={menuButtonRef}
          aria-label='Toggle Menu Visibility'
        >
          <IconFA icon={faPlus} />
        </button>
      </div>

      <Flex wide justifyCenter alignCenter>
        <Link href='/'>
          {
            If(themeState.theme === 'default', () => (
              <a 
                className='MobileHeader__Logo' 
                style={{ fontSize: 0 }}
              ><img
                  key='/images/logo.svg'
                  src='/images/logo.svg'
                  alt='Addicting Games'
                /></a>
            ))
              .Else(() => (
                <a 
                  className='MobileHeader__Logo' 
                  style={{ fontSize: 0 }}
                ><img
                    key='/images/logoClassic.svg'
                    src='/images/logoClassic.svg'
                    alt='Addicting Games'
                  /></a>
              ))
              .EndIf()
          }
        </Link>

        <Flex className="MobileHeader__Nav">
          <Dropdown right>
            <Slot name='trigger'>
              <Button ariaLabel='Open Navigation Menu'>
                <IconFA icon={faBars} />
              </Button>
            </Slot>
            <Slot name='content'>
              {
                For(menuItems, (item, i) => (
                  <DropdownLink 
                    key={i} 
                    href={item.value}
                  >
                    {
                      If(item.label === 'Game Pass', () => (
                        <span className='MobileHeader__Nav__GamepassUpgradeButton'>
                          {
                            If(themeState.theme === 'default', () => (
                              <strong><img src='/images/gamePass.svg' style={{ height: '1rem' }} alt='GamePass' /></strong>
                            ))
                              .Else(() => (
                                <strong><img src='/images/gamePassClassicWhite.svg' style={{ height: '1rem' }} alt='GamePass' /></strong>
                              ))
                              .EndIf()
                          }
                          {
                            If(!state.user?.hasGamePass, () => (
                              <small>Upgrade</small>
                            )).EndIf()
                          }
                        </span>
                      ))
                        .Else(() => (
                          <>
                            <IconFA icon={item.icon} small /> 
                            <span>{item.label}</span>
                          </>
                        ))
                        .EndIf()
                    }
                  </DropdownLink>
                ))
              }
            </Slot>
          </Dropdown>
        </Flex>
      </Flex>

      <div className='MobileHeader__Menu' ref={ref}>
        
        <div className='MobileHeader__Menu__Bg'
          onClick={() => setMobileMenuOpen(false)}
        />
        
        <div className='MobileHeader__Menu__Panel'>
          <AgSearch placeholder='Search' className='--marMd1__b' onFocus={() => setOpenPanel('search')} />
          {
            If(!openPanel && isGamePage && currentRecommendedGames?.length > 0, () => (
              <MoreGamesPanel games={currentRecommendedGames} />
            ))
              .EndIf()
          }

          {/* Exit Full Screen Button */}
          <Flex alignCenter justifyCenter className='--marMd0__t'>
            {
              If(fullScreen, () => (
                <Button primary ariaLabel='Close Game' onClick={exitFullScreen} className='MobileHeader__ExitFullScreenBtn'>
                  <IconFA icon={faCompress} />
                  <span className='--padSm0__l'>Close Game</span>
                </Button>
              ))
                .EndIf()
            }
          </Flex>
        </div>

        <div className='MobileHeader__Menu__ExtraButtons'>

          {/* Home Button */}
          <div className='MobileHeader__Menu__ExtraButtonItem'>
            <ButtonLink href='/' rounded primary ariaLabel='Home'>
              <IconFA icon={faHome} />
            </ButtonLink>
          </div>

          {/* Favourite Games Button */}
          {
            If(isLoggedIn && isGamePage, () => (
              <div className='MobileHeader__Menu__ExtraButtonItem'>
                <Button rounded primary onClick={() => favoriteGame()} ariaLabel='Add Game to Favorites'>
                  {
                    If(gameIsFavorite, () => (
                      <IconFA icon={faHeart} />
                    ))
                      .Else(() => (
                        <IconFA icon={faHeartOutline} />
                      ))
                      .EndIf()
                  }
                </Button>
              </div>
            ))
              .EndIf()
          }

          {/* Search Button */}
          <div className='MobileHeader__Menu__ExtraButtonItem'>
            <Button rounded primary onClick={() => togglePanel('search')} ariaLabel='Search for Games'>
              <IconFA icon={faSearch} />
            </Button>
            
          </div>

          {/* User/Login Button */}
          <div className='MobileHeader__Menu__ExtraButtonItem'>
            <Button rounded primary onClick={() => togglePanel('user')} ariaLabel='Open User Menu'>
              <IconFA icon={faUser} />
            </Button>
          </div>
          
          <div className='MobileHeader__Menu__Panel --userPanel'>
            {
              If(isLoggedIn, () => (
                <UserPanel />
              ))
                .Else(() => (
                  <LoginForm 
                    onSuccess={() => setMobileMenuOpen(false)} 
                    onRegisterClicked={() => closeMenuAndOpenModal('register')} 
                    onRecoverPasswordClicked={() => closeMenuAndOpenModal('recoverPassword')} />
                ))
                .EndIf()
            }
          </div>
        </div>
      </div>
    </Container>
  );
});
