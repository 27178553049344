import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

import style from './index.module.scss';

interface BlockHeaderProps extends IntrinsicProps {
  tag?: string
}

export default createComponent<BlockHeaderProps>('BlockHeader', 
  { style }, 
  function BlockHeader ({ className }, props) {
    const Tag = (props.tag || 'div');

    return (
    // @ts-ignore
      <Tag className={className}>
        {props.children}
      </Tag>
    );
  });
