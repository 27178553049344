import { createComponent } from '@/common/util/templateHelpers';

import style from './index.module.scss';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

export default createComponent('AppHeader', { style },
  function AppHeader ({ className, slots }, props) {
    return (
      <header className={className}>
        <div className='AppHeader__Main'>
          <MobileHeader className='MobileHeader'>
            {slots && slots.mobile}
          </MobileHeader>
          <DesktopHeader className='DesktopHeader'>
            {slots && slots.desktop}
          </DesktopHeader>
        </div>
      </header>
    );
  });
