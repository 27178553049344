import { Flex } from '@/common/components';
import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

import style from './index.module.scss';

interface GameTileFilmStripProps extends IntrinsicProps {
  includeOuterPadding?: boolean
}

const classStates = [
  'includeOuterPadding'
];

export default createComponent<GameTileFilmStripProps>('GameTileFilmStrip', 
  { style, classStates }, 
  function GameTileFilmStrip ({ className }, props) {
    return (
      <Flex justifyCenter>
        <Flex 
          className={className}
        >
          {props.children}
        </Flex>
      </Flex>
    );
  });
