import { useRouter } from 'next/router';
import { For, createComponent, IntrinsicProps, If } from '@/common/util/templateHelpers';
import { Genre, Tag } from '@/util/formatters';
import style from './index.module.scss';
import Link from 'next/link';

interface AgAutocompleteTagItemProps extends IntrinsicProps {
  name: string;
  games: number;
  path: string;
}

const AgAutocompleteTagItem = createComponent<AgAutocompleteTagItemProps>('AgAutocompleteGameItem', { style },
  function AgAutocompleteGameItem ({ className }, props) {

    return (
      <Link href={props.path}>
        <div className="AgAutocompleteTagItem">
          <div className="AgAutocompleteTagItem__nameOuter">
            <span className="AgAutocompleteTagItem__nameLabel">Tag</span>
            <span className="AgAutocompleteTagItem__name">{ props.name }</span>
          </div>
          <div className="AgAutocompleteTag__games">
            { props.games } Games
          </div>
        </div>
      </Link>
    );
  });
export default AgAutocompleteTagItem;
