import { useNotifications } from '@/common/hooks/notifications';
import { useAuthStore, deauthorize } from '@/stores/auth';
import { useRouter } from 'next/router';

export function useLogout () {
  const router = useRouter();
  const [ _state, dispatch ] = useAuthStore();
  const { notify } = useNotifications();

  return function logout () {
    if (router.pathname.includes('account')) {
      router.push('/');
    }

    dispatch(deauthorize, {
      onLogout () {
        notify('You\'re now logged out.', 'success');
      },

      onError (err) {
        notify('There was a problem logging you out. Please try again later.', 'error');
      }
    });
  };
}
