import { createComponent, If, IntrinsicProps } from '@/common/util/templateHelpers';

import style from './index.module.scss';

interface BlockPodProps extends IntrinsicProps {
  box?: boolean
  fill?: boolean
  transparentBg?: boolean
  rightAlignAside?: boolean
  centerHeader?: boolean
}

const classStates = [
  'box',
  'fill',
  'transparentBg',
  'rightAlignAside',
  'centerHeader',
];

export default createComponent<BlockPodProps>('BlockPod', 
  { style, classStates }, 
  function BlockPod ({ className, style, slots }, props) {
    return (
      <div className={'Block ' + className} style={style}>
        <header>
          <div>
            {slots.title}
          </div>
          {
            If(slots.aside, () => (
              <aside>
                {slots.aside}
              </aside>
            )).EndIf()
          }
        </header>
        <div>
          {slots.defaultSlot}
        </div>
      </div>
    );
  });
