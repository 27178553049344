import { useNotifications } from '@/common/hooks/notifications';
import { useEffect, useRef, useState } from 'react';
import Search, { SearchResult } from '@/models/Search';
import { useClickOutside } from '@/common/hooks/clickOutside';

export function useAutocomplete ({ 
  query,
}) {
  const { notify } = useNotifications();
  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const [ searchResults, setSearchResults ] = useState<SearchResult>({
    tags: [],
    results: [],
  });
  const [ showMenu, setShowMenu ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutside({
    ref: ref,
    excludeRefs: [
      { matches: 'div.AgAutocompleteItem' }
    ]
  }, () => {
    setShowMenu(false);
  });

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!query || !query.length || query.length < 3) {
      setShowMenu(false);
      return;
    };

    (async () => {
      setShowMenu(false);
      setLoading(true);

      window.clearTimeout(debounceRef.current);
      debounceRef.current = setTimeout(async () => {
        try {
          const result = await Search.getSearchResults(query);
          setSearchResults(result);
        } catch (err) {
          notify('Error searching games. Please try again later.', 'error');
        }

        setShowMenu(true);
        setLoading(false);
      }, 100);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ query ]);

  return {
    ref,
    searchResults, 
    showMenu,
    loading,
  };
}
