import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

import style from './index.module.scss';

export const DEFAULT_AVATAR_URL = '/images/DefaultProfilePic.png';
export const BASE_AVATAR_URL = process.env.NEXT_PUBLIC_IMAGE_DOMAIN;

export const getUserAvatarUrl = (userAvatar) => userAvatar ? 
  BASE_AVATAR_URL + userAvatar :
  DEFAULT_AVATAR_URL;

interface UserAvatarProps extends IntrinsicProps {
  avatarUrl?: string
  displayName?: string
  small?: boolean
  large?: boolean
}

const userAvatarClassStates = [
  'small',
  'large'
];

export default createComponent<UserAvatarProps>('UserAvatar', 
  { style, classStates: userAvatarClassStates }, 
  function UserAvatar ({ className }, props) {

    const avatarUrl = getUserAvatarUrl(props.avatarUrl);

    return (
      <div className={className}>
        <img src={avatarUrl} alt={props.displayName || avatarUrl} />
      </div>
    );
  });
