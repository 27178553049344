import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

import style from './index.module.scss';

interface GameTileContainerProps extends IntrinsicProps {
  withAdGutter?: boolean
  alignLeft?: boolean
}

const classStates = [
  'alignLeft',
  'withAdGutter'
];

export default createComponent<GameTileContainerProps>('GameTileContainer', 
  { style, classStates }, 
  function GameTileContainer ({ className }, props) {

    return (
      <div className={className}>
        {props.children}
      </div>
    );
  });
