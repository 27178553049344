import { useRouter } from 'next/router';
import { For, createComponent, IntrinsicProps, If } from '@/common/util/templateHelpers';
import { Genre, Tag } from '@/util/formatters';
import style from './index.module.scss';
import Link from 'next/link';
import rating from '@/common/util/rating';

interface AgAutocompleteGameItemProps extends IntrinsicProps {
  thumbnail?: string;
  title: string;
  rating?: string;
  genre?: Genre;
  path: string;
  featured?: boolean;
  trending?: boolean;
}

const AgAutocompleteGameItem = createComponent<AgAutocompleteGameItemProps>('AgAutocompleteGameItem', { style },
  function AgAutocompleteGameItem ({ className }, props) {
    
    return (
      <Link href={props.path}>
        <div className="AgAutocompleteGameItem">
          <div className='AgAutocompleteGameItem__thumbnailOuter'>
            <img alt="Search item thumbnail" className='AgAutocompleteGameItem__thumbnail' src={props.thumbnail}></img>
          </div>
          <div className='AgAutocompleteGameItem__metadata'>
            <div className='AgAutocompleteGameItem__title'>
              { props.title }
            </div>
            <div className='AgAutocompleteGameItem__ratingOuter'>
              <img alt="Rating star icon" className='AgAutocompleteGameItem__ratingIcon' src='/images/Star.svg' />
              <span className='AgAutocompleteGameItem__rating'>
                { rating(Number(props.rating || 0)) }
              </span>
            </div>
            <div className='AgAutocompleteGameItem__genre'>
              { props.genre }
            </div>
            <div className='AgAutocompleteGameItem__flags'>
              {
                If(props.featured, () => (
                  <span className='AgAutocompleteGameItem__flagFeatured'>FEATURED</span>
                )).EndIf()
              }
              {
                If(props.trending, () => (
                  <span className='AgAutocompleteGameItem__flagTrending'>TRENDING</span>
                )).EndIf()
              }
            </div>
          </div>
        </div>
      </Link>
    );
  });
export default AgAutocompleteGameItem;
